<template>
  <section
    v-if="product"
    class="invoice-preview-wrapper"
  >
    <b-row
      class="invoice-preview"
    >

      <!-- Col: Left (Invoice Container) -->
      <b-col
        cols="12"
        xl="9"
        md="8"
      >
        <barcode :value="product.id" />

      </b-col>

      <!-- Right Col: Card -->
      <b-col
        cols="12"
        md="4"
        xl="3"
        class="invoice-actions"
      >
        <b-card>
          <!-- Button: Print -->
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            class="mb-75"
            block
            @click="print"
          >
            Print
          </b-button>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import VueBarcode from 'vue-barcode'

import {
  BRow, BCol, BCard, BButton, VBToggle,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axiosIns from '@/libs/axios'

export default {
  name: 'ShowWorkCertificate',
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  components: {
    barcode: VueBarcode,
    BRow,
    BCol,
    BCard,
    BButton,
  },
  data() {
    return {
      workCertificate: null,
      product: {},
    }
  },
  created() {
    this.getProduct()
  },
  methods: {
    async getProduct() {
      const { data } = await axiosIns.get(`/products/${this.$route.params.id}`)
      this.product = { ...data }
    },
    format(date) {
      if (!date) {
        return null
      }
      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },

  },
  setup() {
    const print = () => {
      window.print()
    }

    return {
      print,

    }
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";

.ta {
    white-space: nowrap;
    font-size: 18px;
    color: black;
  }
th
 {
  padding: 10px;
  text-align: left;
}
.trborder{
  border-bottom: 1px solid black;
}

table {
  font-size: 18px;
  width: 100%;
}

.contact {
  background-color: #0b6746;
  padding: 10px;
  border-radius: 20px;
}
</style>

<style lang="scss">
@media print {
   .ta {
    white-space: nowrap;
    overflow-x: visible;
    font-size: 12px;
    color: black;
  }
  footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: auto; /* Footer height */
  }

  .table thead th {
    border-bottom: 2px solid black;
  }

  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }

  .contact {
    background-color: #0b6746;
    padding: 20px;
    border-radius: 25px;
    -webkit-print-color-adjust: exact;
  }
  footer footer-light footer-static {
    display: inline-block;
  }
  footer.footer {
    display: none;
  }

  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
  .tdt
  {
    padding: 10px;
    text-align: left;
    border: 1px solid black;
  }
  .tht
  {
    padding: 10px;
    text-align: left;
  }

  table {
    width: 100%;
    border-collapse: collapse;
  }
}
</style>
